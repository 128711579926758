#login-page,
#signup-page,
#demo-login-details,
#support-page {
  .login-logo {
    padding: 20px 0;
    //min-height: 20px;
    text-align: center;
  }

  .login-logo img {
    max-width: 10em;
    vertical-align: middle;
    //min-height: 20px;
  }

  .list {
    margin-bottom: 0;
  }

  .login-block {
    max-width: 50em;
    margin: 0 auto;
  }
}

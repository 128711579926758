#newItemLine {
  ion-select {
    justify-content: left;
  }
}

#newShoppingTrip,
#newShoppingList {
  .ion-item-background {
    /*--background: #fff url("../theme/Pastle Bck.png") no-repeat center center /
      cover;*/
    --background: linear-gradient(to right, #ece4dd, #f3efe9, #dfd2c8);
  }
}

#shoppingTripItem,
#shoppingListItem {
  .ion-icon-tertiary {
    color: #516851;
  }
}

#subscriptionPage {
  .freemium-compare-grid {
    max-width: 50em;
    margin: 0 auto;
  }
}

#listItems {
  .ion-item-list {
    //padding: 0 0 0 0;
    //margin: 0 0 0 0;
    //height: auto;
    //text-align: left;
    //--inner-padding-top: 0;
    cursor: pointer;
    //color: #516851;
    /*:hover {
      background-color: #516851;
    }*/
  }
}

#gridTimeActivityList {
  .grid-header-row {
    border-bottom: 1px;
  }
}

#autoSuggestString {
  .suggestions-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 100;
    padding: 10px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
  }
}

#login-page,
#signup-page,
#home-page,
#support-page {
  .login-logo {
    padding: 20px 0;
    min-height: 20px;
    text-align: center;
  }

  .login-logo img {
    max-width: 50px;
    //min-height: 20px;
  }

  .list {
    margin-bottom: 0;
  }

  .login-block {
    max-width: 50em;
    margin: 0 auto;
  }

  .button-icon-top {
    height: 10em;

    .button-inner {
      flex-flow: column;

      .icon {
        margin-bottom: 10px;
        font-size: 20em;
      }
    }
  }

  .button-icon {
    //height: 5em;
    //font-size: 10px;
    zoom: 3;
  }

  .ion-row {
    height: 15em;
  }
}

#logo {
  .login-logo {
    padding: 20px 0;
    min-height: 20px;
    text-align: center;
  }

  .login-logo img {
    max-width: 50px;
    min-height: 20px;
  }
}

#background {
  .content-block {
    --background: #fff url("../theme/Pastle Bck.png") no-repeat center center /
      cover;
    //background-image: url("../images/Background.jpg");
    //background-repeat: no-repeat;
    //-webkit-background-size: cover;
    //-moz-background-size: cover;
    //background-size: cover;
  }
}
